main {
	margin-left: 100px;
	padding: 38px 38px 48px;

	&.p-no-header {
		margin-left: 0 !important;
	}
}

@media print {
	.noprint {
		visibility: hidden;
	}
}
