/* GENERAL */
.patterns-chart-container {
    // margin-top: 40px;
    width: 100%;
    margin-top: 0;
}

.patterns-legend-container {
    margin-top: 40px;

    .legend-block {
        width: 30px;
    }

    .legend-block-text {
        width: 30px;
    }
}

.p-from-to-calendar-label {
    margin-right: 4px;
}

.p-from-to-datepicker {
    span[data-p-disabled='false'] {
        color: $secondary;
        font-weight: bold;
    }
}

.p-from-to-calendar {
    border: 1px solid $fourth;
    background-color: $fourth;
    color: $primary;
    border-radius: 20px;
    padding: 4px;
    margin: 0;
    width: 75% !important;

    input {
        &::placeholder {
            color: $primary !important;
        }
    }
}

.p-from-to-calendar-info-icon {
    display: block;
    float: right;
    padding-top: 5px;
}

.patterns-factor-panel-container {
    width: 100%;
    margin: 5px 0;

    .p-panel-header {
        padding-bottom: 10px;
    }

    .p-panel-title {
        display: inline-block;
        border-top: 1px solid $fourth;
        width: 93%;
        height: 7px;
    }

    .p-panel-content {
    }

    .patterns-metric-container {
        margin: 0 auto;
        margin-bottom: 10px;
        text-align: center;

        &.metric-disabled {
            opacity: 0.5;
        }

        label {
            display: inline-block;
        }

        .patterns-factor-panel-dropdown {
            margin-left: 10px;
            width: 60% !important;
            // display: inline-block;
            text-align: left;
        }

        .patterns-numeric-input {
            width: 50px;

            input {
                border: 1px solid;
            }
        }

        &.patterns-metric-bins-container {
            label {
                padding: 0 5px;
            }
        }
    }
}

.patterns-panel-toggle {
    color: $fourth;
}
@media screen and (min-width: 1600px) {
    .patterns-legend-container {
        margin-top: -83px !important;

        .legend-block {
            width: 40px;
        }

        .legend-block-text {
            width: 40px;
        }
    }
}

@media screen and (min-width: 992px) {
    .patterns-chart-container {
        // margin-top: -100px !important;
        width: auto;
        margin-left: -150px !important;
    }

    .patterns-legend-container {
        margin-top: -83px !important;
    }
}