﻿.p-fileupload-content {
	position: relative
}

.p-fileupload-row {
	display: flex;
	align-items: center
}

.p-fileupload-row > div {
	flex: 1 1 auto;
	width: 25%
}

.p-fileupload-row > div:last-child {
	text-align: right
}

.p-fileupload-content > .p-progressbar {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0
}

.p-button.p-fileupload-choose {
	position: relative;
	overflow: hidden
}

.p-fileupload-buttonbar {
	display: flex;
	flex-wrap: wrap
}

.p-button.p-fileupload-choose input[type=file] {
	display: none
}

.p-fileupload-choose.p-fileupload-choose-selected input[type=file] {
	display: none
}

.p-fileupload-filename {
	word-break: break-all
}

.p-fileupload-file-thumbnail {
	flex-shrink: 0
}

.p-fileupload-file-badge {
	margin: .5rem
}

.p-fluid .p-fileupload .p-button {
	width: auto
}
