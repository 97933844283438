.p-button-config-help {
	padding: 5px 3px 5px 5px !important;
	position: absolute;
	right: 10px;
	top: 10px !important;
	border-radius: 20px !important;
}

.p-config-dialog {
	width: 70vw;
	max-height: 70vh;
	margin: 0px;
	border: 1px solid $primary !important;
	border-radius: 10px !important;
	overflow: hidden;

	.p-dialog-header {
		background-color: $fourth !important;
		padding: 10px 25px !important;


		.p-dialog-title {
			font-size: 1.0em !important;
			font-weight: bold;
		}
	}

	.p-dialog-content {
		padding-top: 2rem !important;
		white-space: pre-line;
	}
}