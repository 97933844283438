$primary: #004547;
$secondary: #c05220;
$tertiary: #f0a583;
$fourth: #bfd9dd;
$fiveth: #8fb9bf;
$sixth: #2e999e;
$seventh: #dd7d07;
$eighth: #effafb;
$nineth: #bc4712;

$bg-body: #effafb;
$gray-light: #f8f9fa;
$gray: #e0e0e0;
$progressBg: #e8e8e8;
$gray-dark: #494949;
$white: #ffffff;