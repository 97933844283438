.p-menu-overlay {
	position: absolute;
}

.p-menu ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.p-menu .p-menuitem-link {
	cursor: pointer;
	display: flex;
	align-items: center;
	text-decoration: none;
	overflow: hidden;
	position: relative;
}

.p-menu .p-menuitem-text {
	line-height: 1;
}

.p-menu {
	padding: 0.25rem 0;
	background: #ffffff;
	color: $primary;
	border: 1px solid #dee2e6;
	border-radius: 3px;
	width: 12.5rem;
}

.p-menu .p-menuitem-link {
	padding: 0.75rem 1rem;
	color: $primary;
	border-radius: 0;
	transition: box-shadow 0.2s;
	user-select: none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
	color: $primary;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
	color: $primary;
	margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
	color: $primary;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
	background: #e9ecef;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
	color: $primary;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
	color: $primary;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
	color: $primary;
}

.p-menu .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-menu.p-menu-overlay {
	background: #ffffff;
	border: 0 none;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-menu .p-submenu-header {
	margin: 0;
	padding: 0.75rem 1rem;
	color: $primary;
	background: #ffffff;
	font-weight: 600;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.p-menu .p-menu-separator {
	border-top: 1px solid #dee2e6;
	margin: 0.25rem 0;
}