
.p-multiselect {
	background: #ffffff;
	border: 1px solid #bfd9dd;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: 6px;
}

.p-multiselect:not(.p-disabled):hover {
	border-color: #bfd9dd;
}

.p-multiselect:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 2px #004547;
}

.p-multiselect.p-multiselect-clearable .p-multiselect-label {
	padding-right: 1.75rem;
}

.p-multiselect .p-multiselect-label {
	padding: 6px 6px 7px 6px;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-multiselect .p-multiselect-label.p-placeholder {
	color: #004547;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
	padding: 0.375rem 0.75rem;
	margin-right: 0.5rem;
	background: #EEF2FF;
	color: #4338CA;
	border-radius: 6px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
	margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
	background: transparent;
	color: #bfd9dd;
	width: 3rem;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.p-multiselect .p-multiselect-clear-icon {
	color: #6c757d;
	right: 3rem;
}

.p-multiselect.p-invalid.p-component {
	border-color: #e24c4c;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
	padding: 0.375rem 0.75rem;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
	padding: 0.75rem 0.75rem;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
	padding-right: 1.75rem;
}

.p-multiselect-panel {
	background: #ffffff;
	color: #495057;
	border: 0 none;
	border-radius: 6px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-multiselect-panel .p-multiselect-header {
	padding: 0.75rem 1.25rem;
	border-bottom: 1px solid #dee2e6;
	color: #343a40;
	background: #f8f9fa;
	margin: 0;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
	margin: 0 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
	padding-right: 1.75rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
	right: 0.75rem;
	color: #6c757d;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
	width: 2rem;
	height: 2rem;
	color: #6c757d;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
	color: #343a40;
	border-color: transparent;
	background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 2px #004547;
}

.p-multiselect-panel .p-multiselect-items {
	padding: 0.75rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
	margin: 0;
	padding: 0.75rem 1.25rem;
	border: 0 none;
	color: #495057;
	background: transparent;
	transition: box-shadow 0.2s;
	border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
	color: #4338CA;
	background: #EEF2FF;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
	color: #495057;
	background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 2px #004547;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
	margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
	margin: 0;
	padding: 0.75rem 1.25rem;
	color: #343a40;
	background: #ffffff;
	font-weight: 700;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
	padding: 0.75rem 1.25rem;
	color: #495057;
	background: transparent;
}

.p-input-filled .p-multiselect {
	background: #e9ecef;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
	background-color: #e9ecef;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
	background-color: #ffffff;
}

/* Multiselect.css */
.p-multiselect {
	display: inline-flex;
	position: relative;
	user-select: none;
}

.p-multiselect-trigger {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	cursor: pointer;
}

.p-multiselect-label-container {
	overflow: hidden;
	flex: 1 1 auto;
	cursor: pointer;
}

.p-multiselect-label {
	display: block;
	white-space: nowrap;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
}

.p-multiselect-label-empty {
	overflow: hidden;
	visibility: hidden;
}

.p-multiselect-token {
	cursor: default;
	display: inline-flex;
	align-items: center;
	flex: 0 0 auto;
}

.p-multiselect-token-icon {
	cursor: pointer;
}

.p-multiselect .p-multiselect-panel {
	min-width: 100%;
}

.p-multiselect-panel {
	position: absolute;
	top: 0;
	left: 0;
}

.p-multiselect-items-wrapper {
	overflow: auto;
}

.p-multiselect-items {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.p-multiselect-item {
	cursor: pointer;
	display: flex;
	align-items: center;
	font-weight: normal;
	white-space: nowrap;
	position: relative;
	overflow: hidden;
}

.p-multiselect-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.p-multiselect-filter-container {
	position: relative;
	flex: 1 1 auto;
}

.p-multiselect-filter-icon {
	position: absolute;
	top: 50%;
	margin-top: -.5rem;
}

.p-multiselect-filter-container .p-inputtext {
	width: 100%;
}

.p-multiselect-close {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	overflow: hidden;
	position: relative;
	margin-left: auto;
}

.p-multiselect-clear-icon {
	position: absolute;
	top: 50%;
	margin-top: -.5rem;
}

.p-fluid .p-multiselect {
	display: flex;
}
