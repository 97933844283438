.p-paginator {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    margin-right: 23px;
    align-items: center;


    .p-paginator-info {
        margin-right: 8px;
    }

    .p-paginator-element {
        width: 21px;
        height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none 0px;
    }

    .p-paginator-prev,
    .p-paginator-next {
        background-color: transparent;
        border: none;

        svg {
            fill: $fiveth;
        }
    }

    .p-paginator-pages {
        display: flex;
        gap: 8px;

        .p-paginator-page {
            background: $fiveth;
            border-radius: 50px;
            color: $white;
            transition: all 300ms ease;
            font-size: 0.75rem;

            &:not(.p-highlight) {
                &:hover {
                    background-color: $sixth;
                }
            }
        }

        .p-paginator-page.p-highlight {
            background-color: $sixth;
        }
    }
}

.p-paginator .p-paginator-left-content {
  margin-left: 23px;
  margin-right: auto;
  width: 100%;

  .p-button {
    background-color: transparent;
    color: $sixth;
    border-color: transparent;
  }
  .p-button-icon {
    color: $fiveth;
    &:hover {
      color: $sixth;
    }
  }
}

.p-paginator-bottom {
    vertical-align: bottom;
}