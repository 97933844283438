﻿.p-dropdown {
	background: #ffffff;
	border: 1px solid $fourth;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: 5px;
	font-size: 1em;
	font-family: 'Lato', sans-serif;
	color: $primary;
	padding: 6px 5px;

	.p-dropdown-clearable {
		.p-dropdown-label {
			padding-right: 1.5rem;
			color: $fourth;
		}
	}

	.p-dropdown-label {
		background: transparent;
		border: 0 none;
		line-height: 18px;

		.p-placeholder {
			color: #6c757d;
		}

		&:enabled {
			&:focus {
				outline: 0 none;
			}
		}

		&:focus-visible {
			border-color: $primary;
		}
	}

	&:not(.p-disabled):hover {
		// border-color: $fourth;
	}

	&:not(.p-disabled).p-focus {
		outline: auto;
		outline-offset: 0;
		// border-color: $fourth;
	}

	.p-dropdown-trigger {
		background: transparent;
		color: $fourth;
		width: 2.357rem;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		float: right;
		width: 20px;
	}

	.p-dropdown-clear-icon {
		color: #004547;
		right: 2.357rem;
		padding-left: 5px;
		font-size: 0.7rem;
		padding-top: 4px;
	}

	.p-invalid {
		.p-component {
			border-color: #f44336;
		}
	}
}

.p-dropdown-panel {
	position: absolute;
	top: 0;
	left: 0;
	background: #ffffff;
	color: #495057;
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

	.p-dropdown-header {
		padding: 0.5rem 1rem;
		border-bottom: 0 none;
		color: #495057;
		background: #f8f9fa;
		margin: 0;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;

		.p-dropdown-filter {
			padding-right: 1.5rem;
		}

		.p-dropdown-filter-icon {
			right: 0.5rem;
			color: #6c757d;
		}

		.p-dropdown-clearable-filter {
			.p-dropdown-filter {
				padding-right: 3rem;
			}
		}

		.p-dropdown-clearable-filter {
			.p-dropdown-filter-clear-icon {
				right: 2rem;
			}
		}
	}

	.p-dropdown-items {
		padding: 0.5rem 0;
		cursor: pointer;
		font-weight: normal;
		white-space: nowrap;
		position: relative;
		overflow: hidden;
	}

	.p-dropdown-items {
		.p-dropdown-item {
			margin: 0;
			padding: 0.5rem 1rem;
			border: 0 none;
			color: #495057;
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: 0;
		}
	}

	.p-dropdown-items {
		.p-dropdown-item.p-highlight {
			color: #495057;
			background: #E3F2FD;
		}

		.p-dropdown-item {
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: #495057;
						background: #e9ecef;
					}
				}
			}
		}

		.p-dropdown-item-group {
			margin: 0;
			padding: 0.75rem 1rem;
			color: #495057;
			background: #ffffff;
			font-weight: 600;
		}
	}
}

.p-dropdown-items-wrapper {
	overflow: auto;
}

.p-dropdown-panel {
	.p-dropdown-items {
		.p-dropdown-empty-message {
			padding: 0.5rem 1rem;
			color: #495057;
			background: transparent;
		}
	}
}

.pricimetrics-dropdown {
	background-color: $fourth !important;
	border-radius: 20px;
	padding: 3px 5px;

	.p-dropdown-label {
		color: $primary;
		font-size: 0.8rem;
		font-weight: 700;
		padding: 0.25rem;
	}

	.p-dropdown-trigger-icon {
		color: $primary;
	}

	.p-dropdown-clear-icon {
		color: $primary;
		padding-left: 5px;
		font-size: 0.7rem;
		padding-top: 4px;
		// position: absolute;
		// right: 2rem;
		// top: 9px;
	}
}

.pricimetrics-dropdown-panel {
	.p-dropdown-items {
		.p-dropdown-item {
			margin: 0;
			font-size: 0.8rem;
			padding: 0.5rem 0.75rem;
			border: 0 none;
			color: $primary;
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: 0;

			&.p-highlight {
				color: $primary !important;
				background: $eighth !important;
			}

			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						background: $eighth;
					}
				}
			}
		}

		.p-dropdown-item-group {
			margin: 0;
			padding: 0.75rem 1rem;
			color: #495057;
			background: #ffffff;
			font-weight: 600;
			border-bottom: 1px solid $fourth;
		}
	}
}