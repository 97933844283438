/* GENERAL */
.relationship-current-group {
    display: inline-block;
    text-align: center;
    padding-left: 5%;
    width: 90%;
    bottom: -3px;

    .config-subsection-wrapper {
        margin: 0;
        padding: 10px 0;
        border-radius: 25px;
        background-color: $fourth !important;
    }

    label {
        padding: 0;
        font-size: 17px !important;
    }

    .textbox {
        font-size: 17px !important;
    }

    .title {
        font-size: 17px;
    }

    .p-inputtext-readonly {
        pointer-events: none;
        background-color: $fourth !important;
        border: 1px solid $sixth !important;
    }

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .title {
        padding: 0;
        color: $primary;
        /*        display: flex;
        align-content: center;
        align-items: center;*/

        .p-current-group-text {
            display: inline-block;
        }
    }

    .btn {
        padding-left: 8px;
        padding-right: 8px;
    }

    .p-checked-items-checkbox {
        padding-top: 10px;
    }
}

.p-listbox-relationships {
    background-color: $fourth !important;

    .p-listbox-list {
    }

    .p-listbox-item {
        &.p-highlight {
            color: $white !important;
            background-color: $primary !important;
        }
    }

    :not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
       /* color: $primary !important;
        background: $sixth !important;*/
    }
}

.p-relationships-textbox {
    background-color: $fourth !important;
    color: $primary !important;
}

.pi-ladder {
    padding: 8px;
    mask-image: url('../images/icn-ladder.svg');
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: 10px 10px;
    -webkit-mask-image: url('../images/icn-ladder.svg');
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 25px 25px;
    background-color: $primary;
}

.pi-price-as-one {
    mask-image: url('../images/icn-relationship-price-as-one.svg');
    mask-position: center center;
    mask-repeat: no-repeat;
    -webkit-mask-image: url('../images/icn-relationship-price-as-one.svg');
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
}

.p-relationship-button-checkbox {
    :hover {
        background-color: $white;
    }
}

.p-icn-relationship-button {
    mask-size: 20px 20px;
    -webkit-mask-size: 20px 20px;
    background-color: $primary;
    width: 20px;
    height: 20px;
}

.p-icn-relationship-current {
    mask-size: 20px 20px;
    -webkit-mask-size: 20px 20px;
    background-color: $primary;
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
}