/* Special size XXL */
@media screen and (min-width: 1600px) {
	.xxl-1,
	.xxl-2,
	.xxl-3,
	.xxl-4,
	.xxl-5,
	.xxl-6,
	.xxl-7,
	.xxl-8,
	.xxl-9,
	.xxl-10,
	.xxl-11,
	.xxl-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.xxl-1 {
		width: 8.3333%;
	}

	.xxl-2 {
		width: 16.6667%;
	}

	.xxl-3 {
		width: 25%;
	}

	.xxl-4 {
		width: 33.3333%;
	}

	.xxl-5 {
		width: 41.6667%;
	}

	.xxl-6 {
		width: 50%;
	}

	.xxl-7 {
		width: 58.3333%;
	}

	.xxl-8 {
		width: 66.6667%;
	}

	.xxl-9 {
		width: 75%;
	}

	.xxl-10 {
		width: 83.3333%;
	}

	.xxl-11 {
		width: 91.6667%;
	}

	.xxl-12 {
		width: 100%;
	}

	.xxl-offset-12 {
		margin-left: 100%;
	}

	.xxl-offset-11 {
		margin-left: 91.66666667%;
	}

	.xxl-offset-10 {
		margin-left: 83.33333333%;
	}

	.xxl-offset-9 {
		margin-left: 75%;
	}

	.xxl-offset-8 {
		margin-left: 66.66666667%;
	}

	.xxl-offset-7 {
		margin-left: 58.33333333%;
	}

	.xxl-offset-6 {
		margin-left: 50%;
	}

	.xxl-offset-5 {
		margin-left: 41.66666667%;
	}

	.xxl-offset-4 {
		margin-left: 33.33333333%;
	}

	.xxl-offset-3 {
		margin-left: 25%;
	}

	.xxl-offset-2 {
		margin-left: 16.66666667%;
	}

	.xxl-offset-1 {
		margin-left: 8.33333333%;
	}

	.xxl-offset-0 {
		margin-left: 0%;
	}
}
