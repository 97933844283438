// @import '~primereact/checkbox/checkbox.min.css';

.p-checkbox {
	width: 16px;
	height: 16px;
	display: inline-flex;
	cursor: pointer;
	user-select: none;
	vertical-align: bottom;

	&.p-checkbox-disabled {
		opacity: 0.5;
		cursor: default;
	}
}

.p-checkbox .p-checkbox-box {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid $secondary;
	background: $white;
	width: 16px;
	height: 16px;
	color: #212529;
	border-radius: 4px;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
	width: 5px;
	height: 7px;
	border-color: white;
	border-style: solid;
	border-width: 0 2px 2px 0;
	display: inline-block;
	transform: rotate(45deg) translate(-1px, -1px);
	opacity: 1;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon::before {
	display: none;
}

.p-checkbox .p-checkbox-box.p-highlight {
	border-color: $secondary;
	background: $secondary;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
	border-color: #4338CA;
	background: #4338CA;
	color: $white;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
	border-color: #ced4da;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #C7D2FE;
	border-color: $tertiary;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
	border-color: $secondary;
	background: $secondary;
	color: $white;
}

.p-checkbox.p-invalid > .p-checkbox-box {
	border-color: #dc3545;
}