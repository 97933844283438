.p-password {
	position: relative;
	display: inline-flex
}

.p-password-panel {
	position: absolute;
	top: 0;
	left: 0
}

.p-password .p-password-panel {
	min-width: 100%
}

.p-password-meter {
	height: 10px
}

.p-password-strength {
	height: 100%;
	width: 0;
	transition: width 1s ease-in-out
}

.p-fluid .p-password {
	display: flex
}

.p-password-input::-ms-reveal, .p-password-input::-ms-clear {
	display: none
}

.p-password-panel {
	padding: 1.25rem;
	background: #ffffff;
	color: #495057;
	border: 0 none;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	border-radius: 6px;
}

.p-password-panel .p-password-meter {
	margin-bottom: 0.5rem;
	background: #dee2e6;
}

.p-password-panel .p-password-meter .p-password-strength.weak {
	background: #ea5455;
}

.p-password-panel .p-password-meter .p-password-strength.medium {
	background: #ff9f42;
}

.p-password-panel .p-password-meter .p-password-strength.strong {
	background: #29c76f;
}

.p-password-panel ul {
	list-style: disc;
}