.modal {
	&-table {
		border-radius: 15px;
		position: absolute;
		top: 75%;
		background-color: $white;
		padding: 20px;
		width: 100%;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
		z-index: 1;
	}

	&-close {
		position: absolute;
		top: 5px;
		right: 5px;
		cursor: pointer;
		@extend .btn-none;
	}

	&-xs {
		max-width: 265px;
	}

	&-close {
		&:before {
			width: 8px;
			height: 8px;
			display: inline-block;
			content: "";
			background-color: $fourth;
			mask-image: url('../../images/icn-close.svg');
			mask-position: center center;
			mask-repeat: no-repeat;
			mask-size: 8px 8px;
			-webkit-mask-image: url('../../images/icn-close.svg');
			-webkit-mask-position: center center;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-size: 8px 8px;
		}
	}
}
