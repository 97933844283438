﻿.p-scrollpanel-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    float: left;
}

.p-scrollpanel-content {
    height: calc(100% + 18px);
    width: calc(100% + 18px);
    padding: 0 18px 18px 0;
    position: relative;
    overflow: scroll;
    box-sizing: border-box;
}

.p-scrollpanel-bar {
    position: relative;
    background: #c1c1c1;
    border-radius: 3px;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.25s linear;
}

.p-scrollpanel-bar-y {
    width: 9px;
    top: 0;
}

.p-scrollpanel-bar-x {
    height: 9px;
    bottom: 0;
}

.p-scrollpanel-hidden {
    visibility: hidden;
}

.p-scrollpanel:hover .p-scrollpanel-bar,
.p-scrollpanel:active .p-scrollpanel-bar {
    opacity: 1;
}

.p-scrollpanel-grabbed {
    user-select: none;
}

.scrollpanel-container {
	.p-scrollpanel {
		p {
			padding: .5rem;
			line-height: 1.5;
			margin: 0;
		}

		&.datatable {
			.p-scrollpanel-wrapper {
				border-right: 10px solid $gray-light;
			}

			.p-scrollpanel-bar {
				background-color: $fourth;
				opacity: 1;
				transition: background-color .2s;

				&:hover {
					background-color: $primary;
				}
			}
		}
	}

	.p-col-12 {
		padding: 2rem;
	}
}