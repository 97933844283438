﻿.p-input-icon-left,
.p-input-icon-right {
    position: relative;
    display: inline-block
}

.p-input-icon-left > .p-input-prefix,
.p-input-icon-left > i,
.p-input-icon-left > svg,
.p-input-icon-right > .p-input-suffix,
.p-input-icon-right > i,
.p-input-icon-right > svg {
    position: absolute;
    top: 50%;
    margin-top: -.5rem
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
    right: 0.75rem;
    color: #6c757d;
}

.p-input-icon-right > .p-inputtext {
    padding-right: 2.5rem;
}

.p-fileupload {
    float: right;
}

.p-button.p-fileupload-choose {
    .p-icon {
        display: none;
    }
}
