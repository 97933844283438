.p-badge {
	display: inline-block;
	border-radius: 10px;
	text-align: center;
	padding: 0 .5rem
}

.p-overlay-badge {
	position: relative
}

.p-overlay-badge .p-badge {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%,-50%);
	transform-origin: 100% 0;
	margin: 0
}

.p-badge-dot {
	width: .5rem;
	min-width: .5rem;
	height: .5rem;
	border-radius: 50%;
	padding: 0
}

.p-badge-no-gutter {
	padding: 0;
	border-radius: 50%
}

.p-badge {
	background: #6366F1;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: 700;
	min-width: 1.5rem;
	height: 1.5rem;
	line-height: 1.5rem;
}

.p-badge.p-badge-secondary {
	background-color: #64748B;
	color: #ffffff;
}

.p-badge.p-badge-success {
	background-color: #22C55E;
	color: #ffffff;
}

.p-badge.p-badge-info {
	background-color: #3B82F6;
	color: #ffffff;
}

.p-badge.p-badge-warning {
	background-color: #F59E0B;
	color: #ffffff;
}

.p-badge.p-badge-danger {
	background-color: #EF4444;
	color: #ffffff;
}

.p-badge.p-badge-lg {
	font-size: 1.125rem;
	min-width: 2.25rem;
	height: 2.25rem;
	line-height: 2.25rem;
}

.p-badge.p-badge-xl {
	font-size: 1.5rem;
	min-width: 3rem;
	height: 3rem;
	line-height: 3rem;
}
