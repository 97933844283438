.p-component, .p-component * {
    box-sizing: border-box
}

.p-hidden {
    display: none
}

.p-hidden-space {
    visibility: hidden
}

.p-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.p-hidden-accessible input, .p-hidden-accessible select {
    transform: scale(0)
}

.p-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    font-size: 100%;
    list-style: none
}

.p-disabled, .p-disabled * {
    cursor: default !important;
    pointer-events: none;
    user-select: none
}

.p-component-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.p-overflow-hidden {
    overflow: hidden
}

.p-unselectable-text {
    user-select: none
}

.p-scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px
}

@-webkit-keyframes p-fadein {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes p-fadein {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.p-link {
    text-align: left;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
    user-select: none
}

.p-link:disabled {
    cursor: default
}

.p-connected-overlay {
    opacity: 0;
    transform: scaleY(0.8);
    transition: transform .12s cubic-bezier(0,0,0.2,1),opacity .12s cubic-bezier(0,0,0.2,1)
}

.p-connected-overlay-visible {
    opacity: 1;
    transform: scaleY(1)
}

.p-connected-overlay-hidden {
    opacity: 0;
    transform: scaleY(1);
    transition: opacity .1s linear
}

.p-connected-overlay-enter {
    opacity: 0;
    transform: scaleY(0.8)
}

.p-connected-overlay-enter-active {
    opacity: 1;
    transform: scaleY(1);
    transition: transform .12s cubic-bezier(0,0,0.2,1),opacity .12s cubic-bezier(0,0,0.2,1)
}

.p-connected-overlay-enter-done {
    transform: none
}

.p-connected-overlay-exit {
    opacity: 1
}

.p-connected-overlay-exit-active {
    opacity: 0;
    transition: opacity .1s linear
}

.p-toggleable-content-enter {
    max-height: 0
}

.p-toggleable-content-enter-active {
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 1s ease-in-out
}

.p-toggleable-content-enter-done {
    transform: none
}

.p-toggleable-content-exit {
    max-height: 1000px
}

.p-toggleable-content-exit-active {
    overflow: hidden;
    max-height: 0;
    transition: max-height .45s cubic-bezier(0,1,0,1)
}

.p-sr-only {
    border: 0;
    clip: rect(1px,1px,1px,1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important
}

.clear-filter {
	cursor: pointer;
	color: #2e999e;
	font-weight: 600;
	display: inline-block;

	&:hover {
		color: #bfd9dd;
	}
}

.scroll-panel-filters {
	width: 100%;
	height: 255px;
}