.p-tabview-nav {
	display: flex;
	margin: 0;
	padding: 0;
	list-style-type: none;
	flex-wrap: wrap
}

.p-tabview-nav-link {
	cursor: pointer;
	user-select: none;
	display: flex;
	align-items: center;
	position: relative;
	text-decoration: none;
	overflow: hidden
}

.p-tabview-ink-bar {
	display: none;
	z-index: 1
}

.p-tabview-nav-link:focus {
	z-index: 1
}

.p-tabview-title {
	line-height: 15px;
}


.p-tabview .p-tabview-nav {
	background: #ffffff;
	border: 1px solid #dee2e6;
	border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li {
	margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	border: solid #dee2e6;
	border-width: 0 0 2px 0;
	border-color: transparent transparent #dee2e6 transparent;
	background: #ffffff;
	color: #6c757d;
	padding: 1rem;
	font-weight: 600;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	transition: box-shadow 0.2s;
	margin: 0 0 -2px 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
	background: #ffffff;
	border-color: #6c757d;
	color: #6c757d;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	background: #ffffff;
	border-color: #2196F3;
	color: #2196F3;
}

.p-tabview .p-tabview-panels {
	background: #ffffff;
	padding: 1rem;
	border: 0 none;
	color: #495057;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}