/* GENERAL */
body {
  background-color: $bg-body;
  font-family: 'Lato', sans-serif;
}
.hidden {
  display: none;
}

/* TITLES */
h1 {
  font-size: 3.375rem;
  font-weight: 700;
  margin-bottom: 58px;
  line-height: 45px;
}
h2 {
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 5px;
}
h3 {
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 5px;
}
h4 {
	font-size: 0.85rem;
	font-weight: 600;
	margin-bottom: 5px;

	&.text-subtitle {
		top: -45px;
		position: relative;
	}
}
h6 {
	margin: 1.5rem 0 1rem;
	margin-top: 1.5rem;
	font-family: inherit;
	font-weight: 600;
	line-height: 1.2;
	color: inherit;
}

a {
	text-decoration: none;
	font-weight: bold;
	color: inherit;

	&:hover {
		text-decoration: underline;
	}
}

p {
	color: $primary;
	font-size: 1rem;
	font-weight: normal;
	padding: 5px 0;
}

b {
	font-weight: bold;
}


.p-legal-content {
	h3 {
		margin: 10px 0 !important;
		color: $primary;
	}

	ul {
		list-style-position: outside;
		list-style-type: disc;
		padding: 5px 0 5px 20px;
		color: $primary;

		li {
			padding: 2px 0;
		}
	}

	.list-none-with-padding {
		list-style: none !important;

		li {
			padding: 5px 0 !important;
		}
	}
}

.p-legal-table {
	width: 100%;
	vertical-align: middle;
	border: medium none;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: inherit;
	color: inherit;
	line-height: inherit;
	margin: 10px 0;

	thead {
		tr {
			td {
				font-weight: bold;
			}
		}
	}

	tr {
		border: 1px solid $primary;

		td {
			color: $primary;
			padding: 5px 10px;
			text-align: left;
			border: 0;
			border-right-color: currentcolor;
			border-right-style: none;
			border-right-width: 0px;
			border-right: 1px solid $primary;
		}
	}
}

.text {
	/* TEXT COLOR */
	&-primary {
		color: $primary !important;
	}

	&-secondary {
		color: $secondary !important;
	}

	&-tertiary {
		color: $tertiary !important;
	}

	&-fourth {
		color: $fourth !important;
	}

	&-fiveth {
		color: $fiveth !important;
	}

	&-sixth {
		color: $sixth !important;
	}

	&-seventh {
		color: $seventh !important;
	}

	&-white {
		color: $white !important;
	}
	/* TEXT SIZE */
	// BASE IS 16PX = 1REM
	&-xl {
		font-size: 1.5rem !important;
	}

	&-lg {
		font-size: 1.25rem;
	}

	&-md {
		font-size: 0.938rem;
	}

	&-sm {
		font-size: 0.813rem;
	}
	/* TEXT DECORATION */
	&-underline {
		text-decoration: underline;
	}
	/* Font Weight */
	&-font-medium {
		font-weight: 600;
	}

	&-font-semibold {
		font-weight: 600;
	}

	&-font-bold {
		font-weight: 700;
	}
}

/* line color */
.hr {
  border-bottom: 0px;
  &-primary {
	border-color: $primary;
  }
  &-gray {
	border-color: $gray;
  }
}

/* BG Color */
.bg {
	&-primary {
		background-color: $primary;
	}

	&-secondary {
		background-color: $secondary;
	}

	&-tertiary {
		background-color: $tertiary;
	}

	&-fourth {
		background-color: $fourth;
	}

	&-fiveth {
		background-color: $fiveth;
	}

	&-sixth {
		background-color: $sixth;
	}

	&-seventh {
		background-color: $seventh;
	}

	&-gray {
		background-color: $gray;
	}

	&-white {
		background-color: $white;
	}
}

/* Border */

.border {
	&-primary {
		border-color: $primary;
	}

	&-secondary {
		border-color: $secondary;
	}

	&-tertiary {
		border-color: $tertiary;
	}

	&-fourth {
		border-color: $fourth;
	}

	&-fiveth {
		border-color: $fiveth;
	}

	&-sixth {
		border-color: $sixth;
	}

	&-seventh {
		border-color: $seventh;
	}

	&-gray {
		border-color: $gray;
	}

	&-white {
		border-color: $white;
	}
}


.rotate {
	&-180 {
		transform: rotate(180deg);
	}

	&-90 {
		transform: rotate(90deg);
	}
}

.p-general-loading-overlay {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	backdrop-filter: blur(2px);
}

.p-error {
	color: #ff0000;
	margin-left: 5px;
	font-size: 0.75rem;
}

.pi {
	font-size: 1rem;
}

.p-footer {
	margin-top: 10px;

	.p-terms-privacy {
		text-align: center;

		a {
			color: $sixth !important;
		}

		span {
			color: $sixth !important;
			padding: 0 5px;
		}
	}

	.p-build-number {
		font-size: 0.85rem;
		font-weight: 700;
		float: right;
		text-align: right;
	}
}


.p-line-chart-legend {
	height: 3px; 
	width: 30px;
	margin-bottom: 3px;
	margin-right: 5px;
	display: inline-block
}

/* custom icons */
.pi-equal {
	font-size: 18px;
	padding-bottom: 4px;
	font-weight: bold;

	&:before {
		content: '=';
	}
}

.pi-contextDialog-warning {
	font-size: 18px;
	padding: 10px;
	background: url('../../assets/images/icn-warning.svg') no-repeat center;

	&:before {
		content: ' ';
	}
}
.pi-contextDialog-tick {
	font-size: 18px;
	padding: 10px;
	background: url('../../assets/images/icn-check.svg') no-repeat center;

	&:before {
		content: ' ';
	}
}

.confirm-panel {
	.p-dialog-header {
		.p-dialog-title {
			@extend .text-primary;
			@extend .text-lg;
		}
	}

	.p-dialog-content {
		.p-confirm-dialog-message {
			@extend .text-primary;
			margin-left: 0 !important;
		}
	}

	.p-dialog-footer {
		text-align: center !important;

		.p-button {
		}

		.p-confirm-dialog-accept {
			@extend .btn-sixth;
		}

		.p-confirm-dialog-reject {
			@extend .btn-secondary;
		}
	}
}

.p-pill-separator {
	border-top: 1px solid $fourth;
	padding-top: 15px;
}

@media print {
	.no-print, .no-print * {
		display: none !important;
	}

	.print-background {
		-webkit-print-color-adjust: exact !important;
		print-color-adjust: exact !important;
	}

	.print-fullwidth {
		width: 100% !important;
		margin-top: 80px !important;
	}
}