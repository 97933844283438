.guardrails-panel-title {
	display: inline-block;
	vertical-align: top;
	padding-top: 2px;
	font-weight: 800;
}

.guardrails-panel-icon {
	display: inline-block !important;
	margin-right: 1em;
}

.p-overlaypanel a {
	color: $secondary;
}

.pricing-field {
	.dropdown-wrapper {
		width: 250px;
		display: inline-block;
	}

	input[type='text'] {
		flex-grow: 1;
		background: none;
		border: none;
		width: 90%;
		color: $primary;
		/*border-bottom: 1px solid $fiveth;*/
		font-size: 0.75rem;

		&::placeholder {
			color: $fiveth;
		}

		&:focus {
			outline: none;
		}
	}

	label {
		color: $primary;
	}
}

.pricingPaneTileChart {
	.p-chart {
		height: 15vw;
	}
}

@media screen and (max-width: 992px) {
	.pricingPaneTileChart {
		.p-chart {
			height: 40vw;
		}
	}
}