/* Check Pill  Form */
.check-pill-wrapper {
  display: flex;
  flex-wrap: wrap;
  .check-pill {
    position: relative;
    margin-bottom: 16px;
    label {
      display: flex;
      height: 100%;
      transition: all 300ms ease;
      color: $primary;
      background-color: $fourth;
    }
    input {
      position: absolute;
      opacity: 0;
    }
    /* Color */
    &.check-pill-tertiary {
      cursor: pointer;
      input:checked {
        + label {
          background-color: $tertiary;
          color: $white;
        }
      }
    }
    /* Size */
    &.check-pill-circle {
      label {
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.813rem;
      }
    }
    &.check-pill-md {
      label {
        padding: 6px 24px;
        font-size: 0.813rem;
      }
    }

    /* Shape */
    &.check-pill-rounded-full {
      label {
        border-radius: 50px;
      }
    }
  }
}

/* Filter Search */
.filter-search {
  display: flex;
  input {
    flex-grow: 1;
    background: none;
    border: none;
    color: $primary;
    border-bottom: 1px solid $fiveth;
    font-size: 0.75rem;
    &::placeholder {
      color: $fiveth;
    }
    &:focus {
      outline: none;
    }
  }
}

/* Checkbox */
.form-checkbox {
    display: flex;
    line-height: 14px;

    &.disabled {
        opacity: 0.5;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
            ~ .form-checkmark {
                &:after {
                    opacity: 1;
                }
            }
        }

        &:disabled {
            ~ .form-checkmark {
                opacity: 0.2;
            }
        }
    }

    .form-checkmark {
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
            content: ' ';
            border: solid;
            transform: rotate(45deg);
            opacity: 0;
        }
    }
    /* Color */
    &.form-checkbox-tertiary {
        .form-checkmark {
            background-color: $fiveth;

            &:after {
                border-color: solid $primary;
            }
        }
    }
    /* Size */
    &.form-checkbox-md {
        .form-checkmark {
            height: 15px;
            width: 15px;
            margin-top: -1px;

            &:after {
                margin-top: -3px;
                width: 3px;
                height: 6px;
                border-width: 0 1px 1px 0;
            }
        }
    }
}

.config-section-wrapper {
	display: block;
	background-color: $white;
	overflow: hidden;
	border-radius: 10px;

	h2 {
		color: $primary;
		font-size: 1.5em;
		font-weight: bold;
		display: inline-block;
	}
}

.config-subsection-wrapper {
	/*display: block;*/
	background-color: $white;

	h3 {
		position: relative;
		color: $primary;
		font-size: 1.0em;
		background-color: $fourth;
		border-radius: 15px;
		display: inline-block;
		width: 100%;
		font-weight: 600;
	}

	label {
		color: $primary;
	}

	.textbox {
		border: 1px solid $fourth;
		border-radius: 5px;
		padding: 5px;
		color: $primary;
		font-size: 1em;
		width: 100%;
		font-size: 1em;
		font-family: 'Lato', sans-serif;

		&:focus-visible {
			border: 1px solid $primary;
		}
	}

	.bottom-buttons {
		.btn {
			background-color: $fourth;
			color: $primary;

			&:hover {
				&:not([disabled]) {
					background-color: $primary;
					color: $white;
				}
			}
		}
	}
}

.p-align-items-start {
    align-items: flex-start !important;
}

.p-button {
	outline: none;
	border: 0px;
	font-family: 'Lato', sans-serif;
	cursor: pointer;
	text-decoration: none;
	line-height: inherit;
	background-color: $fourth;
	color: $primary;
	font-weight: 600;
	font-size: 1rem;
	border-radius: 50px;
	padding: 7px 15px;
	// top: 7px;
}

.radio-relationship-wrapper {
    display: flex;
    flex-wrap: wrap;

    .radio-item {
        position: relative;

        label {
            display: flex;
            height: 100%;
            transition: all 300ms ease;
            color: $primary;
            background-color: $fourth;
        }

        input {
            position: absolute;
            opacity: 0;
        }
        /* Color */
        &.radio-item-tertiary {
            cursor: pointer;

            input:hover, input:checked {
                + label {
                    background-color: $tertiary;
                    color: $white;

                    .pi-ladder {
                        background-color: $white;
                        transition: all 300ms ease;
                    }

                    .pi-price-as-one {
                        background-color: $white;
                        transition: all 300ms ease;
                    }
                }
            }
        }
        /* Size */
        &.radio-item-circle {
            label {
                height: 25px;
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.813rem;
            }
        }

        &.radio-item-md {
            label {
                padding: 8px 24px;
                font-size: 0.813rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        /* Shape */
        &.radio-item-rounded-full {
            label {
                border-radius: 50px;
            }
        }
    }
}