.btn {
	outline: none;
	border: 0px;
	font-family: 'Lato', sans-serif;
	cursor: pointer;
	text-decoration: none;
	line-height: inherit;

	&-none {
		background: none;
		border: none;
	}

	&[disabled] {
		cursor: not-allowed;
	}

	&.btn-inline-block {
		display: inline-block;
	}
	/* SIZE */
	&-xs {
		padding: 4px 9px;
		font-size: 0.75rem;
		transition: all 300ms ease;
	}

	&-md {
		padding: 9px 22px;
		font-size: 0.938rem;
		font-weight: 600;
		transition: all 300ms ease;
	}
	/* COLOR */
	&-primary {
		background-color: $primary;
		color: $white;

		&:hover {
			background-color: lighten($primary, 10%);
		}
	}

	&-secondary {
		background-color: $secondary;
		color: $white;

		&:hover {
			background-color: lighten($secondary, 10%);
		}
	}

	&-tertiary {
		background-color: $tertiary;
		color: $white;

		&:hover {
			background-color: darken($tertiary, 10%);
		}
	}

	&-fourth {
		background-color: $fourth;
		color: $white;

		&:hover {
			background-color: darken($fourth, 10%);
		}
	}

	&-fiveth {
		background-color: $fiveth;
		color: $white;

		&:hover {
			background-color: darken($fiveth, 10%);
		}
	}

	&-sixth {
		background-color: $sixth;
		color: $white;

		&:hover {
			background-color: darken($sixth, 10%);
		}
	}

	&-nineth {
		padding: 4px 5px;
		margin-left: 10px;
		background-color: $nineth;
		color: $white;

		&:hover {
			background-color: darken($nineth, 10%);
		}
	}
	/* ROUNDED */
	&-rounded-full {
		border-radius: 50px;
	}
}

.btn-circle {
	width: 20px;
	height: 20px;
	border-radius: 50px;
	border: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&.bg {
		&-primary {
			svg {
				fill: $white;
			}
		}

		&-secondary {
			svg {
				fill: $white;
			}
		}

		&-tertiary {
			svg {
				fill: $white;
			}
		}

		&-fourth {
			svg {
				fill: $white;
			}
		}

		&-fiveth {
			svg {
				fill: $white;
			}
		}

		&-sixth {
			svg {
				fill: $white;
			}
		}

		&-seventh {
			svg {
				fill: $white;
			}
		}
	}
}

.btn-search {
	width: 20px;
	height: 20px;

	&:before {
		width: 20px;
		height: 19px;
		display: inline-block;
		content: "";
		background-color: $fiveth;
		mask-image: url('../../images/icn-search.svg');
		mask-position: center center;
		mask-repeat: no-repeat;
		mask-size: 20px 19px;
		-webkit-mask-image: url('../../images/icn-search.svg');
		-webkit-mask-position: center center;
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-size: 20px 19px;
	}
}

.btn-export {
	width: 34px;

	&:before {
		width: 24px;
		height: 24px;
		display: inline-block;
		content: "";
		background-color: $white;

		mask-image: url('../../images/icn-donwload.svg');
		mask-position: center center;
		mask-repeat: no-repeat;
		mask-size: 24px 24px;

		-webkit-mask-image: url('../../images/icn-donwload.svg');
		-webkit-mask-position: center center;
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-size: 24px 24px;
	}
}

.btn-column-chooser {
	width: 34px;

	&:before {
		width: 22px;
		height: 22px;
		display: inline-block;
		content: "";
		background-color: $sixth;
		mask-image: url('../../images/icn-column.svg');
		mask-position: center center;
		mask-repeat: no-repeat;
		mask-size: 22px 22px;
		-webkit-mask-image: url('../../images/icn-column.svg');
		-webkit-mask-position: center center;
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-size: 22px 22px;
	}
}