/* Animation */
.p-overlaypanel {
	position: absolute;
	margin-top: 10px;

	&:after {
		bottom: 100%;
		left: calc(var(--overlayArrowLeft, 0) + 1.25rem);
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-width: 8px;
		margin-left: -8px;
	}

	&:before {
		bottom: 100%;
		left: calc(var(--overlayArrowLeft, 0) + 1.25rem);
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-width: 10px;
		margin-left: -10px;
	}
}
.p-overlaypanel-flipped {
  margin-top: 0;
  margin-bottom: 10px;
  &:after {
    bottom: auto;
    top: 100%;
  }
  &:before {
    bottom: auto;
    top: 100%;
  }
}
.p-overlaypanel-close {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.p-overlaypanel-enter {
  opacity: 0;
  transform: scaleY(0.8);
}
.p-overlaypanel-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1),
    opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
}
.p-overlaypanel-enter-done {
  transform: none;
}
.p-overlaypanel-exit {
  opacity: 1;
}
.p-overlaypanel-exit-active {
  opacity: 0;
  transition: opacity 0.1s linear;
}
.p-overlaypanel.p-overlaypanel-flipped {
  &:after {
    border-bottom-color: transparent;
  }
  &:before {
    border-bottom-color: transparent;
  }
}

.p-overlaypanel {
  background: #ffffff;
  color: #323130;
  border: 0 none;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px,
    rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
  &.p-overlaypanel-rounded-lg {
    border-radius: 15px;
    &::after,
    &::before {
      display: none;
    }
  }
}
.p-overlaypanel .p-overlaypanel-content {
	padding: 1rem;

	.overlay-title {
		.icon {
			margin-bottom: -1px;
			display: inline-block;
		}

		.warning {
			&:before {
				width: 17px;
				height: 17px;
				display: block;
				content: "";
				background-color: $sixth;
				mask-image: url('../../images/icn-warning.svg');
				mask-position: center center;
				mask-repeat: no-repeat;
				mask-size: 17px 17px;
				-webkit-mask-image: url('../../images/icn-warning.svg');
				-webkit-mask-position: center center;
				-webkit-mask-repeat: no-repeat;
				-webkit-mask-size: 17px 17px;
			}
		}
	}
}
.overlaypanel-wrapper.nopadding .p-overlaypanel-content {
  padding: 0rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #0078d4;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 2px;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #106ebe;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}