﻿


.p-confirm-popup {
	background: #ffffff;
	color: #495057;
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-confirm-popup .p-confirm-popup-content {
	padding: 1rem;
}

.p-confirm-popup .p-confirm-popup-footer {
	text-align: right;
	padding: 0 1rem 1rem 1rem;
}

.p-confirm-popup .p-confirm-popup-footer button {
	margin: 0 0.5rem 0 0;
	width: auto;
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
	margin: 0;
}

.p-confirm-popup:after {
	border: solid transparent;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ffffff;
}

.p-confirm-popup:before {
	border: solid transparent;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:after {
	border-top-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:before {
	border-top-color: #ffffff;
}

.p-dialog-content .p-confirm-dialog-icon {
	font-size: 1.5rem;
	width: 1.5em;
	height: 1.5em;
	margin-top: 1rem;
}

.p-confirm-popup .p-confirm-popup-message {
	margin-left: 1rem;
}