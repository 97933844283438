﻿

.p-dialog {
	border-radius: 3px;
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	border: 0 none;
}

.p-dialog .p-dialog-header {
	border-bottom: 0 none;
	background: #ffffff;
	color: #495057;
	padding: 1.5rem;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.p-dialog .p-dialog-header .p-dialog-title {
	font-weight: 600;
	font-size: 1.25rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
	width: 2rem;
	height: 2rem;
	color: #6c757d;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: #e9ecef;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
	margin-right: 0;
}

.p-dialog .p-dialog-content {
	background: #ffffff;
	color: #495057;
	padding: 0 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-footer {
	border-top: 0 none;
	background: #ffffff;
	color: #495057;
	padding: 0 1.5rem 1.5rem 1.5rem;
	text-align: right;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.p-dialog .p-dialog-footer button {
	margin: 0 0.5rem 0 0;
	width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
	font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
	margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
	background-color: rgba(0, 0, 0, 0.4);
}

.p-dialog-mask {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	background-color: transparent;
	transition-property: background-color;
}

.p-dialog-visible {
	display: flex;
}

.p-dialog-mask.p-component-overlay {
	pointer-events: auto;
}

.p-dialog {
	display: flex;
	flex-direction: column;
	pointer-events: auto;
	max-height: 90%;
	transform: scale(1);
	position: relative;
}

.p-dialog-content {
	overflow-y: auto;
	flex-grow: 1;
}

.p-dialog-header {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.p-dialog-footer {
	flex-shrink: 0;
}

.p-dialog .p-dialog-header-icons {
	display: flex;
	align-items: center;
	align-self: flex-start;
	flex-shrink: 0;
}

.p-dialog .p-dialog-header-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
}

.p-dialog .p-dialog-title {
	flex-grow: 1;
}

/* Fluid */
.p-fluid .p-dialog-footer .p-button {
	width: auto;
}

/* Animation */
/* Center */
.p-dialog-enter {
	opacity: 0;
	transform: scale(0.7);
}

.p-dialog-enter-active {
	opacity: 1;
	transform: scale(1);
	transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
}

.p-dialog-enter-done {
	transform: none;
}

.p-dialog-exit-active {
	opacity: 0;
	transform: scale(0.7);
	transition: all 150ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

/* Top, Bottom, Left, Right, Top* and Bottom* */
.p-dialog-top .p-dialog,
.p-dialog-bottom .p-dialog,
.p-dialog-left .p-dialog,
.p-dialog-right .p-dialog,
.p-dialog-top-left .p-dialog,
.p-dialog-top-right .p-dialog,
.p-dialog-bottom-left .p-dialog,
.p-dialog-bottom-right .p-dialog {
	margin: .75em;
}

.p-dialog-top .p-dialog-enter,
.p-dialog-top .p-dialog-exit-active {
	transform: translate3d(0px, -100%, 0px);
}

.p-dialog-bottom .p-dialog-enter,
.p-dialog-bottom .p-dialog-exit-active {
	transform: translate3d(0px, 100%, 0px);
}

.p-dialog-left .p-dialog-enter,
.p-dialog-left .p-dialog-exit-active,
.p-dialog-top-left .p-dialog-enter,
.p-dialog-top-left .p-dialog-exit-active,
.p-dialog-bottom-left .p-dialog-enter,
.p-dialog-bottom-left .p-dialog-exit-active {
	transform: translate3d(-100%, 0px, 0px);
}

.p-dialog-right .p-dialog-enter,
.p-dialog-right .p-dialog-exit-active,
.p-dialog-top-right .p-dialog-enter,
.p-dialog-top-right .p-dialog-exit-active,
.p-dialog-bottom-right .p-dialog-enter,
.p-dialog-bottom-right .p-dialog-exit-active {
	transform: translate3d(100%, 0px, 0px);
}

.p-dialog-top .p-dialog-enter-active,
.p-dialog-bottom .p-dialog-enter-active,
.p-dialog-left .p-dialog-enter-active,
.p-dialog-top-left .p-dialog-enter-active,
.p-dialog-bottom-left .p-dialog-enter-active,
.p-dialog-right .p-dialog-enter-active,
.p-dialog-top-right .p-dialog-enter-active,
.p-dialog-bottom-right .p-dialog-enter-active {
	transform: translate3d(0px, 0px, 0px);
	transition: all .3s ease-out;
}

.p-dialog-top .p-dialog-exit-active,
.p-dialog-bottom .p-dialog-exit-active,
.p-dialog-left .p-dialog-exit-active,
.p-dialog-top-left .p-dialog-exit-active,
.p-dialog-bottom-left .p-dialog-exit-active,
.p-dialog-right .p-dialog-exit-active,
.p-dialog-top-right .p-dialog-exit-active,
.p-dialog-bottom-right .p-dialog-exit-active {
	transition: all .3s ease-out;
}

/* Maximize */
.p-dialog-maximized {
	transition: none;
	transform: none;
	width: 100vw !important;
	height: 100vh !important;
	max-height: 100%;
	top: 0px !important;
	left: 0px !important;
}

.p-dialog-maximized .p-dialog-content {
	flex-grow: 1;
}

/* Position */
.p-dialog-left {
	justify-content: flex-start;
}

.p-dialog-right {
	justify-content: flex-end;
}

.p-dialog-top {
	align-items: flex-start;
}

.p-dialog-top-left {
	justify-content: flex-start;
	align-items: flex-start;
}

.p-dialog-top-right {
	justify-content: flex-end;
	align-items: flex-start;
}

.p-dialog-bottom {
	align-items: flex-end;
}

.p-dialog-bottom-left {
	justify-content: flex-start;
	align-items: flex-end;
}

.p-dialog-bottom-right {
	justify-content: flex-end;
	align-items: flex-end;
}

.p-confirm-dialog .p-dialog-content {
	display: flex;
	align-items: center;
}

/* Resizable */
.p-dialog .p-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	display: block;
	cursor: se-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	bottom: 1px;
}

.p-dialog-draggable .p-dialog-header {
	cursor: move;
}
