﻿.p-slider {
    position: relative
}

.p-slider .p-slider-handle {
    position: absolute;
    cursor: grab;
    touch-action: none;
    display: block;
    z-index: 1
}

.p-slider .p-slider-handle.p-slider-handle-active {
    z-index: 2
}

.p-slider-range {
    position: absolute;
    display: block
}

.p-slider-horizontal .p-slider-range {
    top: 0;
    left: 0;
    height: 100%
}

.p-slider-horizontal .p-slider-handle {
    top: 50%
}

.p-slider-vertical {
    height: 100px
}

.p-slider-vertical .p-slider-handle {
    left: 50%
}

.p-slider-vertical .p-slider-range {
    bottom: 0;
    left: 0;
    width: 100%
}


/* Specific theme */
.p-slider {
    background: $sixth;
    border: 0 none;
    border-radius: 6px;
}

.p-slider.p-slider-horizontal {
    height: 0.286rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem;
}

.p-slider.p-slider-vertical {
    width: 0.286rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem;
}

.p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #ffffff;
    border: 2px solid $primary;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
}

.p-slider .p-slider-range {
    background: $fourth;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: $sixth;
    border-color: $fourth;
}

.pricimetrics-slider {
    span.label {
        color: $primary;
        font-size: 14px;
        margin-top: 10px;
    }
}