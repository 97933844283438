﻿$menuIconWidth: 42px;
$menuIconHeight: 42px;

header {
	background-color: $primary;
	padding: 32px 24px;
	position: fixed;
	left: 0px;
	top: 0px;
	height: 100vh;
	overflow-y: auto;
	display: flex;
	flex-direction: column;

	.header-logo {
		margin-bottom: 54px;

		a {
			width: 55px;
			height: 55px;
			display: inline-block;

			&:before {
				width: 55px;
				height: 55px;
				display: inline-block;
				content: "";
				background-image: url('../../images/pricimetrics-logo.svg');
				background-repeat: no-repeat;
			}
		}
	}

	.header-nav {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;

		a {
			margin-bottom: $menuIconHeight;
		}
	}

	.header-user {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.p-button {
			padding: 0px;
			background-color: transparent;
		}

		a {
			margin-bottom: 28px;

			&.is-active {
				img {
					opacity: 0.5;
				}
			}

			&:last-child {
				margin-bottom: 0px;
			}
		}
	}

	.header-icon {
		&:before {
			width: $menuIconWidth;
			height: $menuIconHeight;
			display: inline-block;
			content: "";
			background-color: $white;
			mask-position: center center;
			mask-repeat: no-repeat;
			-webkit-mask-position: center center;
			-webkit-mask-repeat: no-repeat;
		}

		&.is-active {
			opacity: 0.5;
		}

		&.home {
			&:before {
				mask-image: url('../../images/icn-home.svg');
				-webkit-mask-image: url('../../images/icn-home.svg');
				mask-size: 31px 27px;
				-webkit-mask-size: 31px 27px;
			}
		}

		&.dashboard {
			&:before {
				mask-image: url('../../images/icn-dashboard.svg');
				-webkit-mask-image: url('../../images/icn-dashboard.svg');
				mask-size: $menuIconHeight 33px;
				-webkit-mask-size: $menuIconWidth $menuIconHeight;
			}
		}

		&.relationship {
			&:before {
				mask-image: url('../../images/icn-relationship.svg');
				-webkit-mask-image: url('../../images/icn-relationship.svg');
				mask-size: $menuIconWidth $menuIconHeight;
				-webkit-mask-size: $menuIconWidth $menuIconHeight;
			}
		}

		&.pricing {
			&:before {
				mask-image: url('../../images/icn-pricing.svg');
				-webkit-mask-image: url('../../images/icn-pricing.svg');
				mask-size: $menuIconWidth $menuIconHeight;
				-webkit-mask-size: $menuIconWidth $menuIconHeight;
			}
		}

		&.admin {
			&:before {
				mask-image: url('../../images/icn-cog-solid.svg');
				-webkit-mask-image: url('../../images/icn-cog-solid.svg');
				mask-size: $menuIconWidth $menuIconHeight;
				-webkit-mask-size: $menuIconWidth $menuIconHeight;
			}
		}

		&.patterns {
			&:before {
				mask-image: url('../../images/icn-patterns.svg');
				-webkit-mask-image: url('../../images/icn-patterns.svg');
				mask-size: $menuIconWidth $menuIconHeight;
				-webkit-mask-size: $menuIconWidth $menuIconHeight;
			}
		}

		&.browse {
			&:before {
				mask-image: url('../../images/icn-list-alt-solid.svg');
				-webkit-mask-image: url('../../images/icn-list-alt-solid.svg');
				mask-size: $menuIconWidth $menuIconHeight;
				-webkit-mask-size: $menuIconWidth $menuIconHeight;
			}
		}
	}
}
