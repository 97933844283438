// @import '~primereact/avatar/avatar.min.css';

.p-avatar {
	background-color: $fiveth;
}

.p-avatar.p-avatar-lg {
	width: 3rem;
	height: 3rem;
	font-size: 1.5rem;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
	font-size: 1.5rem;
}

.p-avatar.p-avatar-xl {
	width: 4rem;
	height: 4rem;
	font-size: 2rem;
}

.p-avatar.p-avatar-xl .p-avatar-icon {
	font-size: 2rem;
}

.p-avatar-group .p-avatar {
	border: 2px solid #ffffff;
}
