.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton {
	.p-radiobutton-box {
		border: 2px solid #C7DCDF;
		background: #C7DCDF;
		width: 20px;
		height: 20px;
		color: rgba(255, 255, 255, 0.87);
		border-radius: 50%;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

		.p-radiobutton-icon {
			width: 12px;
			height: 12px;
			transition-duration: 0.2s;
		}

		&.p-highlight {
			color: $white;

			&:after {
				position: relative;
				top: -1px;
				left: -1px;
				content: '\2714';
			}
		}

		&:not(.p-disabled) {
			:not(.p-highlight):hover {
				border-color: #64B5F6;
			}

			.p-focus {
				outline: 0 none;
				outline-offset: 0;
			}

			:hover {
				color: #212529;
			}
		}
	}

	&-primary {
		.p-radiobutton-box {
			border: 2px solid $primary;
			background: $primary;

			&.p-highlight {
				color: #C7DCDF;
			}
		}
	}

	&-secondary {
		.p-radiobutton-box {
			border: 2px solid $secondary;
			background: $secondary;
		}
	}

	&-tertiary {
		.p-radiobutton-box {
			border: 2px solid $tertiary;
			background: $tertiary;

			&.p-highlight {
				color: #C7DCDF;
			}
		}
	}

	&-fourth {
		.p-radiobutton-box {
			border: 2px solid $fourth;
			background: $fourth;

			&.p-highlight {
				color: #C7DCDF;
			}
		}
	}

	&-fiveth {
		.p-radiobutton-box {
			border: 2px solid $fiveth;
			background: $fiveth;

			&.p-highlight {
				color: #C7DCDF;
			}
		}
	}

	&-sixth {
		.p-radiobutton-box {
			border: 2px solid $sixth;
			background: $sixth;

			&.p-highlight {
				color: $white;
			}
		}
	}

	&-seventh {
		.p-radiobutton-box {
			color: rgba(33, 37, 41, 0.87);
			border: 2px solid $seventh;
			background: $seventh;

			&.p-highlight {
				color: #212529;
			}
		}
	}

	&-white {
		.p-radiobutton-box {
			border: 2px solid #C7DCDF;
			background: $white;

			&.p-highlight {
				color: #C7DCDF;
			}
		}
	}
}

.p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: #ef9a9a;
}
.p-radiobutton:focus {
  outline: 0 none;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #383838;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #383838;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #64B5F6;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #2396f2;
}