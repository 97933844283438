﻿.p-toast {
	position: fixed;
	width: 25rem;
}

.p-toast-message-content {
	display: flex;
	align-items: flex-start;
}

.p-toast-message-text {
	flex: 1 1 auto;
}

.p-toast-top-right {
	top: 20px;
	right: 20px;
}

.p-toast-top-left {
	top: 20px;
	left: 20px;
}

.p-toast-bottom-left {
	bottom: 20px;
	left: 20px;
}

.p-toast-bottom-right {
	bottom: 20px;
	right: 20px;
}

.p-toast-top-center {
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
}

.p-toast-bottom-center {
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
}

.p-toast-center {
	left: 50%;
	top: 50%;
	min-width: 20vw;
	transform: translate(-50%, -50%);
}

.p-toast-icon-close {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
}

.p-toast-icon-close.p-link {
	cursor: pointer;
}

/* Animations */
.p-toast-message-enter {
	opacity: 0;
	transform: translateY(50%);
}

.p-toast-message-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: transform .3s, opacity .3s;
}

.p-toast-message-enter-done {
	transform: none;
}

.p-toast-message-exit {
	opacity: 1;
	max-height: 1000px;
}

.p-toast-message-exit-active {
	opacity: 0;
	max-height: 0;
	margin-bottom: 0;
	overflow: hidden;
	transition: max-height .45s cubic-bezier(0, 1, 0, 1), opacity .3s, margin-bottom .3s;
}


.p-toast {
	opacity: 0.9;
	position: fixed;
	width: 25rem;

	.p-toast-message {
		margin: 0 0 1rem 0;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		border-radius: 3px;

		.p-toast-message-content {
			padding: 1rem;
			border-width: 0 0 0 6px;

			.p-toast-message-text {
				margin: 0 0 0 1rem;
			}

			.p-toast-message-icon {
				font-size: 2rem;
			}

			.p-toast-summary {
				font-weight: 700;
			}

			.p-toast-detail {
				margin: 0.5rem 0 0 0;
			}
		}

		.p-toast-icon-close {
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			background: transparent;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			background-image: url('../../images/icn-close.svg') !important;
			background-repeat: no-repeat !important;
			background-position: center !important;
		}

		.p-toast-icon-close:hover {
			background: rgba(255, 255, 255, 0.3);
		}

		.p-toast-icon-close:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem #a6d5fa;
		}

		&.p-toast-message-info {
			background: #B3E5FC;
			border: solid #0891cf;
			border-width: 0 0 0 6px;
			color: #044868;

			.p-toast-message-icon, .p-toast-icon-close {
				color: #044868;
			}
		}

		&.p-toast-message-success {
			background: #C8E6C9;
			border: solid #439446;
			border-width: 0 0 0 6px;
			color: #224a23;

			.p-toast-message-icon, .p-toast-icon-close {
				color: #224a23;
			}
		}

		&.p-toast-message-warn {
			background: #FFECB3;
			border: solid #d9a300;
			border-width: 0 0 0 6px;
			color: #6d5100;

			.p-toast-message-icon, .p-toast-icon-close {
				color: #6d5100;
			}
		}

		&.p-toast-message-error {
			background: #FFCDD2;
			border: solid #e60017;
			border-width: 0 0 0 6px;
			color: #73000c;

			.p-toast-message-icon, .p-toast-icon-close {
				color: #73000c;
			}
		}
	}
}