// @import '~primereact/tieredmenu/tieredmenu.min.css';

.p-tieredmenu {
	padding: 0.25rem 0;
	background: #ffffff;
	color: #495057;
	border: 1px solid #dee2e6;
	border-radius: 6px;
	width: 12.5rem;
}

.p-tieredmenu .p-menuitem-link {
	padding: 0.75rem 1.25rem;
	color: #495057;
	border-radius: 0;
	transition: box-shadow 0.2s;
	user-select: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
	color: #495057;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
	color: #6c757d;
	margin-right: 0.5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
	color: #6c757d;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
	background: #e9ecef;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
	color: #495057;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
	color: #6c757d;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
	color: #6c757d;
}

.p-tieredmenu .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem #C7D2FE;
}

.p-tieredmenu.p-tieredmenu-overlay {
	background: #ffffff;
	border: 0 none;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-tieredmenu .p-submenu-list {
	padding: 0.25rem 0;
	background: #ffffff;
	border: 0 none;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
	background: #EEF2FF;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
	color: #4338CA;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
	color: #4338CA;
}

.p-tieredmenu .p-menu-separator {
	border-top: 1px solid #dee2e6;
	margin: 0.25rem 0;
}

.p-tieredmenu .p-submenu-icon {
	font-size: 0.875rem;
}