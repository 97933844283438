.p-selectbutton .p-button {
	display: inline-block;
	background: $secondary;
	border: 3px solid $tertiary;
	color: #ffffff;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	font-weight: normal !important;
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
	color: #6c757d;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
	background: $tertiary;
	border-color: $tertiary;
	color: #ffffff;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
	color: #343a40;
}

.p-selectbutton .p-button.p-highlight {
	background: $sixth;
	border-color: $sixth;
	color: #ffffff;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
	color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
	background: $sixth;
	border-color: $sixth;
	color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
	color: #ffffff;
}

.p-selectbutton .p-button.p-disabled {
	opacity: 0.5;
}

.p-selectbutton.p-invalid > .p-button {
	border-color: #e24c4c;
	font-size: 0.813rem;
	font-weight: normal;
}

.p-buttonset .p-button {
	margin: 0
}

.p-buttonset .p-button:not(:last-child) {
	border-right: 0
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
	border-radius: 0
}

.p-buttonset .p-button:first-of-type {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.p-buttonset .p-button:last-of-type {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.p-buttonset .p-button:focus {
	position: relative;
	z-index: 1;
	top: 0 !important;
}

.p-buttonset-pricing-grid .p-button:first-of-type {
	background: $primary !important;
	border-color: $primary !important;
	color: #ffffff;
	position: relative;
	top: 0 !important;

	&.p-highlight {
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		border-color: $tertiary !important;
		margin-right: -13px;
		z-index: 2;
	}

	&:not(p-highlight) {
		margin-right: 0;
		z-index: 1;
	}

	.p-button-label {
		padding: 0px 10px !important;
		position: relative;
		top: -1px !important;
	}
}
.p-buttonset-pricing-grid .p-button:not(:last-child) {
	&.p-highlight {
		border: 3px solid $tertiary !important;
	}
}

.p-buttonset-pricing-grid .p-button:last-of-type {
	background: $secondary !important;
	border-color: $secondary !important;
	color: #ffffff;
	position: relative;
	top: 0 !important;

	&.p-highlight {
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		border-color: $tertiary !important;
		margin-left: -13px;
		z-index: 2;
	}

	&:not(p-highlight) {
		margin-left: 0;
		z-index: 1;
	}

	.p-button-label {
		padding: 0px 10px !important;
		position: relative;
		top: -1px !important;
	}
}