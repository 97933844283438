﻿.p-inputnumber {
	display: inline-flex;
	width: 100%;
}

.p-inputwrapper-filled {
	&:not(.p-inputnumber-buttons-stacked) {
		width: 100%;
	}
}

.p-inputnumber .p-inputtext {
	border: 1px solid $fourth;
	border-radius: 5px;
	padding: 5px;
	color: $primary;
	font-size: 1em;
	width: 100%;
	font-family: 'Lato', sans-serif;

	&:focus-visible {
		border: 1px solid $primary;
	}
}

.p-inputnumber-button {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button .p-button-label,
.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button .p-button-label {
	display: none;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top-right-radius: 5px;
	padding: 0;
}

.p-inputnumber-buttons-stacked .p-inputnumber-input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 5px;
	padding: 0;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group {
	display: flex;
	flex-direction: column;
	width: 30px;
	background-color: $fourth;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-button.p-inputnumber-button {
	flex: 1 1 auto;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up {
	order: 3;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.p-inputnumber-buttons-horizontal .p-inputnumber-input {
	order: 2;
	border-radius: 0;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
	order: 1;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.p-inputnumber-buttons-vertical {
	flex-direction: column;
}

.p-inputnumber-buttons-vertical .p-button.p-inputnumber-button-up {
	order: 1;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	width: 100%;
}

.p-inputnumber-buttons-vertical .p-inputnumber-input {
	order: 2;
	border-radius: 0;
	text-align: center;
}

.p-inputnumber-buttons-vertical .p-button.p-inputnumber-button-down {
	order: 3;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	width: 100%;
}

.p-inputnumber-input {
	flex: 1 1 auto;
}

.p-fluid .p-inputnumber {
	width: 100%;
}

.p-fluid .p-inputnumber .p-inputnumber-input {
	width: 1%;
}

.p-fluid .p-inputnumber-buttons-vertical .p-inputnumber-input {
	width: 100%;
}
