﻿
.p-listbox {
	background: #ffffff;
	color: #495057;
	border: 1px solid $fourth;
	border-radius: 6px;
}

.p-listbox .p-listbox-header {
	padding: 0.75rem 1.25rem;
	border-bottom: 1px solid #dee2e6;
	color: #343a40;
	background: #f8f9fa;
	margin: 0;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
}

.p-listbox .p-listbox-header .p-listbox-filter {
	padding-right: 1.75rem;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
	right: 0.75rem;
	color: #6c757d;
}

.p-listbox .p-listbox-list {
	padding: 0.75rem 0;
}

.p-listbox .p-listbox-list .p-listbox-item {
	margin: 0;
	padding: 0.75rem 1.25rem;
	border: 0 none;
	color: #495057;
	transition: box-shadow 0.2s;
	border-radius: 0;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
	color: $primary;
	background: $fourth;
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 1px #c1c2f9;
}

.p-listbox .p-listbox-list .p-listbox-item-group {
	margin: 0;
	padding: 0.75rem 1.25rem;
	color: #343a40;
	background: #ffffff;
	font-weight: 700;
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
	padding: 0.75rem 1.25rem;
	color: #495057;
	background: transparent;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
	color: $primary;
	background: $fourth;
}

.p-listbox.p-invalid {
	border-color: #e24c4c;
}

.p-listbox-list-wrapper {
	overflow: auto;
}

.p-listbox-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.p-listbox-item {
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.p-listbox-filter-container {
	position: relative;
}

.p-listbox-filter-icon {
	position: absolute;
	top: 50%;
	margin-top: -.5rem;
}

.p-listbox-filter {
	width: 100%;
}
