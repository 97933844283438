/* MultiTabWidget.css */

.dashboard-widget {
	padding: 12px 15px 10px 7px;
	background: #effafb;
}

.widget-inner-box {
	width: 100%;
	color: white;
	border-radius: 5px;
}

.dashboard-tab
.p-tabview
.p-tabview-nav
li.p-tabview-selected
.p-tabview-nav-link {
	color: #014548 !important;
}

.dashboard-tab {
	position: relative;
}

.dashboard-tab .p-tabview .p-tabview-nav li a {
	text-decoration: none;
	border: 0 !important;
}

.dashboard-tab
.p-tabview
.p-tabview-nav
li
.p-tabview-nav-link:not(.p-disabled):focus {
	box-shadow: none;
}

.dashboard-tab .p-tabview .p-tabview-nav {
	background: #eefafa;
	border-radius: 10px 10px 0 0;
	font-size: 12px;
	border: 0px solid #dee2e6;
}

.dashboard-tab .p-tabview .p-tabview-nav li:first-child {
	margin-left: 5px;
}

.tab-footer {
	text-align: center;
}

.tab-dots {
	color: #eefafa;
	font-size: 20px;
	cursor: pointer;
	display: inline;
	font-weight: 600;
	margin-right: 5px;
}

.tab-active {
	font-weight: 900;
	color: #014548;
}

.chart-categories {
	text-align: center;
	height: 100%;
}

.chart-categories-first-box {
	border-left: 2px solid #e8e8e8;
}

.chart-categories-percentage {
	font-weight: 600;
	font-size: 20px;
}

.chart-category-name {
	font-size: 12px;
	font-weight: 500;
	color: #014548 !important;
	margin-top: 5px;
}

.category-inner-border {
	height: 37px;
	border-left: 2px solid #e8e8e8;
}

.dashboard-tab .p-tabview .p-tabview-nav li:nth-child(2) .p-tabview-nav-link {
	border-left: 1px solid !important;
	border-right: 1px solid !important;
	height: 11px;
	margin-top: 7px !important;
}

.dashboard-tab .p-tabview .p-tabview-nav li .p-tabview-nav-link {
	background: #eefafa !important;
	margin: 0 !important;
	padding-top: 10px;
	padding-bottom: 11px;
	box-shadow: none;
}

.widget-option-btn {
	position: absolute;
	top: 9px;
	color: #eefafa !important;
	cursor: pointer;
	right: 6px;
}

@media screen and (min-width: 990px) {
	.p-tabview-panels .right_text {
		display: flex;
		justify-content: center;
		margin-top: 25px;
		/* align-items: center; */
		text-align: center;
	}
}

@media screen and (max-width: 990px) {
	.p-tabview-panels .right_text > .grid {
		display: grid;
		grid-template-columns: auto auto;
		padding: 10px;
		margin-top: 10px;
		text-align: center;
	}
}

/* price-direction */

.price-direction .m-top {
	margin-top: 25px;
}

.p-tabview-panels .price-direction,
.p-tabview-panels .price-direction .text-Right {
	display: flex;
	justify-content: center;
}

.price-direction .text-Right .d-flex {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: center;
}

/* end */

/* source of current price */

.display {
	display: inline;
	font-size: 10px;
	color: #eefafa !important;
	margin-top: 3px;
}

.p-tabview-panels .grid .text-center {
	padding-top: 5px;
	margin-left: 30%;
	display: grid;
	justify-content: space-around;
	text-align: center;
}

.d-flex-first {
	display: flex;
}

.d-flex {
	position: relative;
}

.p-tabview-panels .grid .text-center .width-style {
	width: 60%;
}

/* end */

/* End of MultiTabWidget.css */

/* ChartWidget.css */

/*.dashboard-tab .p-tabview .p-tabview-nav {
	background: #b5d4d6;
	border-radius: 10px 10px 0 0;
	font-size: 12px;
}*/

/* End of ChartWidget.css */


/* BarLine.css */

.right-box {
	position: relative;
	vertical-align: middle;
	height: 202px;
	color: #648c8d !important;
}

.right-box-content {
	vertical-align: -webkit-baseline-middle;
	margin: 0;
	position: absolute;
	text-align: center;
	color: #ced6d6 !important;
	top: 36%;
}

.right-box-contents {
	vertical-align: -webkit-baseline-middle;
	/* margin: 30px 0px; */
	position: absolute;
	text-align: center;
}

.right-box-contents > .chart-categories-percentage {
	margin: 25px 0px !important;
}

.chart-categories-percentage > .chart-category-name {
	color: #014548 !important;
}
/* 
.dashboard-tab
  > .tabview-custom
  > .p-tabview-nav
  > .p-unselectable-text
  > .p-tabview-nav-link
  > .p-tabview-title {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #004547 !important;
} */


/* End of BarLine.css */

/* Chart.css */

.dashboard-tab .p-tabview .p-tabview-panels div .card {
	justify-content: left !important;
}

.dashboard-tab .p-tabview .p-tabview-panels div .card .p-chart {
	width: 100% !important;
}

/* End of Chart.css */

/* RepricingPerformanceTab.css */
.dashboard-widget {
	padding: 12px 15px 10px 7px;
	background: #effafb;
}

.widget-inner-box {
	width: 100%;
	color: white;
	border-radius: 5px;
}

.dashboard-tab
.p-tabview
.p-tabview-nav
li.p-tabview-selected
.p-tabview-nav-link {
	color: #304a4b !important;
}

.dashboard-tab {
	position: relative;
}

.dashboard-tab .p-tabview .p-tabview-nav li a {
	text-decoration: none;
	border: 0 !important;
}

.dashboard-tab
.p-tabview
.p-tabview-nav
li
.p-tabview-nav-link:not(.p-disabled):focus {
	box-shadow: none;
}

.dashboard-tab .p-tabview .p-tabview-nav {
	background: #b5d4d6;
	border-radius: 10px 10px 0 0;
	font-size: 12px;
}

.dashboard-tab .p-tabview .p-tabview-nav li:first-child {
	margin-left: 5px;
}

.tab-footer {
	text-align: center;
}

.tab-dots {
	color: #91aeaf;
	font-size: 20px;
	cursor: pointer;
	display: inline;
	font-weight: 600;
	margin-right: 5px;
}

.tab-active {
	font-weight: 900;
	color: #304a4b;
}

.chart-categories {
	text-align: center;
	height: 100%;
}

.chart-categories-first-box {
	border-left: 2px solid #e8e8e8;
}

.chart-categories-percentage {
	font-weight: 600;
	font-size: 20px;
}

.chart-category-name {
	font-size: 12px;
	font-weight: 500;
	color: #014548 !important;
	margin-top: 5px;
}

.category-inner-border {
	height: 37px;
	border-left: 2px solid #e8e8e8;
}

.dashboard-tab .p-tabview .p-tabview-nav li:nth-child(2) .p-tabview-nav-link {
	border-left: 1px solid !important;
	border-right: 1px solid !important;
	border-radius: 0;
}

.dashboard-tab .p-tabview .p-tabview-nav li .p-tabview-nav-link {
	background: #b5d4d6 !important;
	margin: 0 !important;
	padding-top: 8px;
	padding-bottom: 10px;
	box-shadow: none;
}

.widget-option-btn {
	position: absolute;
	top: 9px;
	color: #648c8d !important;
	cursor: pointer;
	right: 6px;
}

.p-tabview-panels .text-right {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-around;
	// align-items: center;
	// text-align: center;
	margin-top: auto;
}

/* End of RepricingPerformanceTab.css */

/* Spotlight.css */

.SpotLightCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child,
.SpotLightCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child li a {
	background: #effafb !important;
	border-color: #014548;
	background: none;
	pointer-events: none;
}

.SpotLightCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child li a {
	padding-left: 0px;
}

.SpotLightCard > .p-tabview:first-child > .p-tabview-panels {
	padding: 0 !important;
}

.SpotLightCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:hover,
.SpotLightCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:focus,
.SpotLightCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:active,
.SpotLightCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:focus-within,
.SpotLightCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:focus-visible {
	border: 0 !important;
	text-decoration: none !important;
	box-shadow: none !important;
}

.SpotLightCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child
li.p-highlight
.p-tabview-nav-link {
	color: #014548 !important;
	border-width: 0 0 2px 0 !important;
}

.SpotLightCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child li {
	/* margin-left: 8px !important; */
}

.SpotLightCard > .p-tabview .p-tabview-panels {
	background: none !important;
	margin-top: 33px !important;

	.SpotLightCard-Container {
		margin-bottom: 30px !important;

		
		padding: 10px 0px !important;
	}
}

.SpotLightCard .font-medium {
	font-weight: 600 !important;
}

.p-tabview-panels .grid {
	background-color: white;
	border-radius: 10px !important;
	// margin: 20px 0px !important;
	// margin-bottom: 30px !important;
	// padding: 10px 0px !important;
}

.card {
	/* padding: 10px !important; */
}

.grid .card .flex {
	display: flex !important;
	align-items: center !important;

	@media (min-width: 1200px) and (max-width: 1600px) {
		flex-wrap: wrap;

		.col-6 {
			width: 100%;
			justify-content: space-between !important;
			padding: 0 !important;
		}
	}
}

.left-text .advertising {
	margin-left: 8px !important;
	font-size: 13px;
	font-weight: 600;
}

.flex .right-text {
	justify-content: flex-end !important;
	text-align: end !important;
}

.left-text > span {
	padding: 4px !important;
}

.left-text > .icon {
	background: #effafb !important;
	border-radius: 5px !important;
	padding: 3px !important;
}

.text-down {
	padding-top: 1px !important;
}

.text-down .text-sm {
	font-size: 12px !important;
	padding-top: 6px !important;
}

.widget-btn {
	position: relative;
	cursor: pointer;
}

.circle-icon {
	padding-right: 8px;
}

/* End of Spotlight.css */

/* Custom.css */

/* Provide sufficient contrast against white background */

/*a {
	color: #0366d6;
}*/

code {
	color: #e01a76;
}

.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}

.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child,
.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child li a {
	background: #effafb !important;
	border: 0;
	/* padding: 0px 0px 16px 0px !important; */
}

.multiChart > .p-tabview:first-child > .p-tabview-panels {
	padding: 0 !important;
}

.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:hover,
.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > :focus,
.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > :active,
.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > :focus-within,
.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > :focus-visible {
	border: 0 !important;
	text-decoration: none !important;
	box-shadow: none !important;
}

.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child
li.p-highlight
.p-tabview-nav-link {
	color: #014548 !important;
	border: solid #014548 !important;
	border-width: 0 0 2px 0 !important;
	border-color: transparent transparent #014548 transparent !important;
	margin-bottom: 15px;
	width: 85%;
}

.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a > .p-tabview-title {
	margin-left: -16px;
}

.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child
li.p-highlight
.p-tabview-nav-link {
	color: #014548 !important;
	border: solid #014548 !important;
	border-width: 0 0 2px 0 !important;
	border-color: transparent transparent #014548 transparent !important;
	margin-bottom: 15px;
}

.multiChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child li {
	margin-left: 12px !important;
}

.dashboard-tab > .tabview-custom > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav > .p-unselectable-text {
	font-size: 0.782rem !important;
	font-weight: 700 !important;
	color: #014548 !important;
}


/* End of Custom.css */

/* ProfitOpportunity.css */

a {
	color: #0366d6;
}

code {
	color: #e01a76;
}

.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}

.profitOpportunityChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child,
.profitOpportunityChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child
li
a {
	background: #effafb !important;
	border: 0;
}

.profitOpportunityChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a > .p-tabview-title {
	margin-left: -16px;
}

.profitOpportunityChart > .p-tabview:first-child > .p-tabview-panels {
	padding: 0 !important;
}

.profitOpportunityChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:hover,
.profitOpportunityChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > :focus,
.profitOpportunityChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > :active,
.profitOpportunityChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > :focus-within,
.profitOpportunityChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > :focus-visible {
	border: 0 !important;
	text-decoration: none !important;
	box-shadow: none !important;
}

.profitOpportunityChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child
li.p-highlight
.p-tabview-nav-link {
	color: #014548 !important;
	border: solid #014548 !important;
	border-width: 0 0 2px 0 !important;
	border-color: transparent transparent #014548 transparent !important;
	margin-bottom: 15px;
}

.profitOpportunityChart > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child
li {
	margin-left: 5px !important;
	width: 96%;
}
/* .dashboard-tab
  > .tabview-custom
  > .p-tabview-nav
  > .p-unselectable-text
  > .p-tabview-nav-link
  > .p-tabview-title {
  font-size: 0.782rem !important;
  font-weight: 700 !important;
  color: #014548 !important;
} */



/* End of ProfitOpportunity.css */


/* StateOfBusiness.css */

.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child,
.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child li a {
	background: #effafb !important;
	border-color: #014548;
	background: none;
	pointer-events: none;
}

.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child li a {
	padding-left: 0px;
}

.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-panels {
	padding: 0 !important;
}

.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:hover,
.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:focus,
.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:active,
.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:focus-within,
.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child > li > a:focus-visible {
	border: 0 !important;
	text-decoration: none !important;
	box-shadow: none !important;
}

.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child
li.p-highlight
.p-tabview-nav-link {
	color: #014548 !important;
	border-width: 0 0 2px 0 !important;
}

.StateOfBusinessCard > .p-tabview:first-child > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav:first-child li {
	/* margin-left: 8px !important; */
}

.StateOfBusinessCard > .p-tabview .p-tabview-panels {
	background: none !important;
	margin-top: 15px !important;

	.StateOfBusinessCard-Container {
		margin-bottom: 30px !important;
		padding: 10px 0px !important;
	}
}

.StateOfBusinessCard .font-medium {
	font-weight: 600 !important;
}

.StateOfBusinessCard {
	table {
		width: 100%;
		color: $primary;
		font-size: 0.7rem;

		thead {
			tr {
				th {
					border: 1px solid $sixth;
					padding: 5px 3px;
					font-weight: bold;
					font-size: 0.7rem;

					&:first-child {
						border: none;
					}
				}
			}
		}

		tbody {
			tr {
				td {
					border: 1px solid $sixth;
					padding: 5px 3px;
					text-align: center;

					&:first-child {
						font-weight: bold;
						text-align: left;
					}
				}
			}
		}

		tfoot {
			tr {
				th {
					border: none;
					padding: 5px 3px;
					font-weight: bold;
					font-size: 0.7rem;
					text-align: left;

					&:first-child {
						border: none;
					}
				}
			}
		}
	}
}
/* End of StateOfBusiness.css */
