﻿.p-chips {
	display: inline-flex;
	border: 1px solid $fourth;
	border-radius: 5px;

	.pi-times-circle {
		background: url('../../images/icn-close.svg') center no-repeat;
		width: 5px;
		height: 5px;
	}

	.pi-times-circle:before {
		content: '';
	}
}

.p-chips-multiple-container {
	margin: 0;
	padding: 0;
	list-style-type: none;
	cursor: text;
	overflow: hidden;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.p-chips-token {
	cursor: default;
	display: inline-flex;
	align-items: center;
	flex: 0 0 auto;
}

.p-chips-input-token {
	flex: 1 1 auto;
	display: inline-flex;
}

.p-chips-token-icon {
	cursor: pointer;
}

.p-chips-input-token input {
	border: 0 none;
	outline: 0 none;
	background-color: transparent;
	margin: 0;
	padding: 0;
	box-shadow: none;
	border-radius: 0;
	width: 100%;
}

.p-fluid .p-chips {
	display: flex;
}

.p-chips .p-chips-multiple-container {
	padding: 0.25rem 0.5rem;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
	border-color: #2196F3;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #a6d5fa;
	border-color: #2196F3;
}

.p-chips .p-chips-multiple-container .p-chips-token {
	padding: 0.25rem 0.5rem;
	margin-right: 0.5rem;
	background: #E3F2FD;
	color: #495057;
	border-radius: 3px;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
	margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
	padding: 0.25rem 0;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 1rem;
	color: #495057;
	padding: 0;
	margin: 0;
}

.p-chips.p-invalid.p-component > .p-inputtext {
	border-color: #f44336;
}

